<template>
  <div>
    <v-row justify="center">
      <v-col md="12">
        <v-row>
          <v-col>
            <v-card>
              <v-card-title primary-title> {{ $t("agenda") }}</v-card-title>
              <v-card-text>
                <v-text-field
                  :label="$t('agenda')"
                  required
                  flat
                  outlined
                  v-model="agenda"
                ></v-text-field>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" md="8">
            <v-card>
              <v-card-title primary-title>
                <v-row>
                  <v-col cols="3">
                    {{ $t("rollCall") }}
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn icon v-bind="attrs" v-on="on">
                          <v-icon>mdi-help-box</v-icon>
                        </v-btn>
                      </template>
                      <span>
                        PV: Present and Voting
                        <span v-if="$root.$i18n.locale === 'zh'"
                          >出席并投票</span
                        >; P: Present
                        <span v-if="$root.$i18n.locale === 'zh'">出席</span>; A:
                        Absent
                        <span v-if="$root.$i18n.locale === 'zh'">缺席</span>;
                        <br />
                        <span v-if="$root.$i18n.locale === 'zh'"
                          >出席并投票在投票时不能弃权</span
                        >
                        <span v-else
                          >Present and Voting will not abstain when voting</span
                        >
                      </span>
                    </v-tooltip>
                  </v-col>
                  <v-col cols="9" class="text-center">
                    <span class="mr-2">
                      <v-icon>mdi-circle-slice-8</v-icon>
                      {{ $t("total") }}
                      :
                      <strong>{{ presentStat[0] }}</strong
                      >;
                    </span>
                    <span class="mr-2">
                      <v-icon>mdi-circle-slice-5</v-icon>
                      {{ $t("23maj") }}
                      :
                      <strong>{{ presentStat[1] }}</strong
                      >;
                    </span>
                    <span class="mr-2">
                      <v-icon> mdi-circle-slice-4</v-icon>
                      {{ $t("12maj") }}
                      :
                      <strong>{{ presentStat[2] }}</strong
                      >;
                    </span>
                    <span class="mr-2">
                      <v-icon> mdi-circle-slice-1</v-icon>
                      {{ $t("15maj") }}
                      :
                      <strong>{{ presentStat[3] }}</strong
                      >;
                    </span>
                  </v-col>
                </v-row>
              </v-card-title>
              <v-card-actions>
                <v-row>
                  <v-col>
                    <v-btn
                      block
                      outlined
                      @click="setAllPresentVoting"
                      color="success"
                    >
                      {{ $t("pv") }}</v-btn
                    >
                  </v-col>
                  <v-col>
                    <v-btn
                      block
                      outlined
                      @click="setAllPresent"
                      color="warning"
                      >{{ $t("p") }}</v-btn
                    >
                  </v-col>
                  <v-col>
                    <v-btn block outlined @click="setAllAbsent" color="error">{{
                      $t("a")
                    }}</v-btn>
                  </v-col>
                </v-row>
              </v-card-actions>
              <v-card-text>
                <div v-if="this.$store.getters.inSessionCountriesEmpty">
                  <v-btn color="primary" to="/setup">
                    {{ $t("addCountries") }}
                  </v-btn>
                </div>
                <v-container style="max-height: 500px" class="overflow-y-auto">
                  <div v-for="(item, index) in inSessionCountries" :key="index">
                    <v-row class="d-flex align-center justify-center">
                      <v-col cols="2" class="flex-shrink-1">
                        <v-avatar size="40">
                          <img :src="getImage(item.code)" alt="item.name_en" />
                        </v-avatar>
                      </v-col>
                      <v-col cols="5" class="flex-grow-1">
                        <span v-if="$root.$i18n.locale === 'zh'">
                          {{ item.name_zh }}
                        </span>
                        {{ item.name_en }}
                      </v-col>
                      <v-col cols="5" class="flex-grow-1">
                        <v-btn
                          class="mr-2 mb-1"
                          color="success"
                          :outlined="item.status === 'PV' ? false : true"
                          @click="changeStatus(item, 'PV')"
                          >PV</v-btn
                        >
                        <v-btn
                          class="mr-2 mb-1"
                          color="warning"
                          :outlined="item.status === 'P' ? false : true"
                          @click="changeStatus(item, 'P')"
                          >P</v-btn
                        >
                        <v-btn
                          class="mr-2"
                          color="error"
                          :outlined="item.status === 'A' ? false : true"
                          @click="changeStatus(item, 'A')"
                          >A</v-btn
                        >
                      </v-col>
                    </v-row>
                    <v-divider></v-divider>
                  </div>
                </v-container>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>


<script>
export default {
  metaInfo() {
    return {
      title: this.$t("rollCall"),
    };
  },

  data() {
    return {
      drawer: null,
      activeBtn: 1,
      fullscreen: false,
    };
  },
  computed: {
    agenda: {
      get() {
        return this.$store.state.currentSession.agenda;
      },
      set(v) {
        this.$store.commit("setAgenda", v);
      },
    },
    inSessionCountries() {
      return this.$store.getters.sortedInSessionCountries;
    },
    presentStat() {
      return this.$store.getters.presentStat;
    },
  },
  methods: {
    restore() {
      var result = confirm("确定清除所有数据并重启么？");
      if (result) {
        window.localStorage.removeItem("vuex");
        location.reload();
      }
    },
    setAllAbsent() {
      this.$store.commit("setAllAbsent");
    },
    setAllPresent() {
      this.$store.commit("setAllPresent");
    },
    setAllPresentVoting() {
      this.$store.commit("setAllPresentVoting");
    },
    toMunshare() {
      window.open("http://www.munshare.com", "_blank");
    },

    getImage(code) {
      if (code.length > 3) {
        return require(`../assets/flags/UN.svg`);
      } else {
        return require(`../assets/flags/${code}.svg`);
      }
    },
    changeStatus(item, status) {
      const payload = {
        item: item,
        status: status,
      };
      this.$store.commit("setStatus", payload);
    },
  },
  created() {},
};
</script>
<i18n>
{
  "en": {
    "agenda": "Agenda",
    "save": "Save",
    "rollCall": "Roll Call",
    "about-munshare": "About Munshare",
    "total": "Total",
    "23maj": "2/3 Majority",
    "12maj": "Simple Majority",
    "15maj": "20%",
    "pv": "Set All Present and Voting",
    "p":"Set All Present",
    "a":"Set All Absent",
    "addCountries":"Please Add Countries in Setup",
    "munshare": "Munshare"
  },
  "zh": {
    "agenda": "议程",
    "save": "保存",
    "rollCall": "点名",
    "about-munshare": "关于模时",
    "total": "出席总数",
    "23maj": "2/3多数",
    "12maj": "简单多数",
    "15maj": "20%",
    "pv": "全部出席并投票",
    "p":"全部出席",
    "a":"全部缺席",
    "addCountries":"请在设置页面添加国家",
    "munshare": "模时"
  }
}
</i18n>
